import React, { useState, useEffect } from "react"

import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import { navigate } from "gatsby"
import moment from "moment"
import { parseCookies } from 'nookies'
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import { Button } from "components/anti/buttons/buttons"
import { Input, DatePicker, Select } from "components/anti/forms/forms"
import { Modal } from "components/anti/modal/modal"
import Seo from "components/seo"

import { CoreParagraph } from "components/gutenberg/core-paragraph"

import { RegisterQuery } from "components/utils/streamworx"

import circle from "assets/img/roll-animate.svg"



const Register = () => {
    const data = useStaticQuery(GET_TNC)
    const blocks = data.wordPress.post.blocks


    const initialRegisterValues = {
        fullName: "",
        email: "",
    }

    const genderOptions = [
        { value: 'MALE', label: 'Laki-laki' },
        { value: 'FEMALE', label: 'Perempuan' },
    ]

    const cookies = parseCookies()

    const authTokenNew = parseCookies().authToken ? `Bearer ${parseCookies().authToken}` : null
    const streamworx_config = { context: { clientName: 'streamworx', headers: { authorization: authTokenNew, }, } }

    const [tnc, setTnc] = useState(false)
    // const [submitData, setSubmitData] = useState()
    const [gender, setGender] = useState(genderOptions[0])
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [showModal, setShowModal] = useState("")
    const [errMsg, setErrMsg] = useState("")


    const [registerStatus, { data: result, loading }] = useMutation(RegisterQuery, streamworx_config)



    const YupRegisterValidation = Yup.object({
        email: Yup.string().email("Email tidak valid").required("Masukkan email anda"),
        fullName: Yup.string().required("Masukkan Nama anda"),
        tnc: Yup.boolean().required("Harap Setuju dengan Syarat & Ketentuan kami")
    })

    //Handle from formik
    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        const phoneNumber = cookies.phoneNumber
        const date = moment(dateOfBirth).format("YYYY-MM-DD")

        const newValues = {
            ...values, dateOfBirth: date, phoneNumber, gender: gender.value
        }
        // setSubmitData(newValues)
        registerStatus({
            variables: { ...newValues }
        }).then(res => {
            console.log("callback", res)
            setSubmitting(false)
            navigate('/register/create-pin')
        }).catch(res => {
            setSubmitting(false)
            setErrMsg("Maaf Terjadi Kesalahan")
            console.log("err", res)
        })
    }


    useEffect(() => {
        if (tnc) {
            setShowModal("tnc")
        }
    }, [tnc])

    useEffect(() => {
        const phoneNumber = cookies.phoneNumber
        if (!phoneNumber) {
            navigate("/")
            // window.location.replace("/")
        }
    }, [])


    return (
        <Layout>
            <Seo title="Register" />
            <section className="py-main sc-register-register">
                <div className="container mw-sm">
                    <Button
                        variant="link"
                        className="btn-back-help ai-arrow-from-left mb-3"
                        link={"/login"}
                    >
                        Kembali
                    </Button>
                    <h2>Data Diri</h2>
                    <p>Lengkapi informasi di bawah ini untuk melanjutkan</p>
                    <Formik
                        initialValues={initialRegisterValues}
                        validationSchema={YupRegisterValidation}
                        onSubmit={handleSubmit}
                    >
                        {({
                            handleSubmit,
                            isSubmitting,
                            handleChange,
                            touched,
                            errors,
                            values
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <Input
                                            floatingLabel
                                            variant={"underline"}
                                            label={"Nama Lengkap Sesuai KTP"}
                                            as="input"
                                            id="fullName"
                                            name="fullName"
                                            onChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <Input
                                            floatingLabel
                                            variant={"underline"}
                                            label={"Alamat E-mail"}
                                            as="input"
                                            type="email"
                                            id="email"
                                            name="email"
                                            onChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <Select
                                            variant="underline"
                                            id="gender"
                                            name="gender"
                                            label="Jenis Kelamin"
                                            value={gender}
                                            options={genderOptions}
                                            onChange={(id, value) => setGender(value)}
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <div className="field field-underline">
                                            <label htmlFor="date" >
                                                Tanggal Lahir
                                            </label>
                                            <div className="form-control-wrapper form-date">
                                                <DatePicker
                                                    selected={dateOfBirth}
                                                    onChange={(date) => setDateOfBirth(date)}
                                                    placeholder="Tanggal Tes"
                                                    className="date-border"
                                                    name="dateOfBirth"
                                                    touched={touched}
                                                    errors={errors}
                                                    maxDate={moment().toDate()}
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-12 mt-5 w-100">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <Field
                                                type="checkbox"
                                                name="tnc"
                                                onClick={() => setTnc(!tnc)}
                                                onChange={handleChange}
                                            />
                                            <p className="mb-0 ml-4">Saya setuju terhadap <a className="font-weight-bold" href={"/syarat-ketentuan"}>Syarat & Ketentuan</a> yang berlaku.</p>
                                        </div>
                                        {errors && errors["tnc"] && (
                                            <div className="invalid-feedback">{errors["tnc"]}</div>
                                        )}
                                    </div>
                                    <div className="form-group col-12">
                                        <Button variant="primary" className="btn-block mt-4" type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? (
                                                <img src={circle} className="img-fluid loading" />
                                            ) : (
                                                "Lanjut"
                                            )}
                                        </Button>
                                        <div className="invalid-feedback">{errMsg}</div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
            <Modal
                id="tnc"
                // title="Pembayaran BPJS Kesehatan"
                // img={bank_bri}
                isShowing={showModal}
                // hide={closeModal}
                className="modal-md bayar-tagihan-modal sc-bpjs-kategori"
            >
                <div className="bayar-tagihan-modal-content animated fadeInDown delayp5 overflow-auto h-500px">
                    <div className="modal-container">
                        <div className="post-content">
                            {blocks &&
                                blocks.map((block, i) => {
                                    switch (block.name) {
                                        case "core/paragraph":
                                            return <CoreParagraph data={block} key={i} />
                                        default:
                                            return <div key={i} />
                                    }
                                })}
                        </div>
                    </div>
                </div>
                <div className="modal-container">
                    <Button variant="primary" className="btn-block" onClick={() => setShowModal("")}>
                        Setuju
                    </Button>
                </div>
            </Modal>
        </Layout>
    )
}

export default Register


const GET_TNC = graphql`
query tncRegis {
    wordPress{
        post(id: "syarat-ketentuan", idType: URI) {
            title
            slug
            blocks {
            ... on WordPress_CoreParagraphBlock {
                name
                attributes {
                    ... on WordPress_CoreParagraphBlockAttributes {
                      align
                      content
                      className
                    }
                  }
                dynamicContent
                originalContent
                }
            }
        }
    }
  }
`

